import React from "react"
import PropTypes from "prop-types"
import { Container, Menu, Sticky } from "semantic-ui-react"

import CookieConsent from "./cookie-consent"
import Footer from "./footer"
import MainMenu from "./menu"
import MenuItemLink from "./menu-item-link"
import MenuItems from "./menu-items"
import ParallaxBackgroundImage from "./parallax-background"

import withMobileLayout from "./with-mobile-layout"

const LayoutDesktopParallax = props => {
  let stickyRef = React.createRef()

  return (
    <>
      <CookieConsent />
      <ParallaxBackgroundImage image={props.image}>
        <Container style={{ height: `100%`, overflow: `hidden` }}>
          <Menu
            inverted
            secondary
            pointing
            size="large"
            style={{ border: `none` }}
          >
            <Container>
              <Menu.Menu position="right">
                <MenuItemLink to="/" name="home" />
                <MenuItems />
              </Menu.Menu>
            </Container>
          </Menu>
          {props.imageChildren && props.imageChildren()}
        </Container>
      </ParallaxBackgroundImage>
      <div ref={stickyRef}>
        <Sticky context={stickyRef}>
          <MainMenu />
        </Sticky>
        <main>
          <div style={{ paddingTop: "3em" }}>{props.children}</div>
        </main>
        <Footer />
      </div>
    </>
  )
}

LayoutDesktopParallax.propTypes = {
  children: PropTypes.node.isRequired,
  image: PropTypes.string.isRequired,
  imageChildren: PropTypes.func,
}

const LayoutDesktopParallaxWithMobileLayout = withMobileLayout(
  LayoutDesktopParallax
)

export {
  LayoutDesktopParallaxWithMobileLayout as default,
  LayoutDesktopParallax,
}
