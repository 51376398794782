import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Container, Header } from "semantic-ui-react"

import { StyledContainer, StyledImage } from "./core"
import renderAst from "./render-markdown"

export default () => {
  const data = useStaticQuery(graphql`
    query howItWorks {
      markdownRemark(
        fileAbsolutePath: { regex: "/src/content/home/how-it-works/" }
      ) {
        htmlAst
        frontmatter {
          image {
            publicURL
          }
          imageAlt
          title
        }
      }
    }
  `)
  const markdownData = data.markdownRemark
  return (
    <>
      <Container>
        <Header as="h2" textAlign="center">
          {markdownData.frontmatter.title}
        </Header>
        <StyledImage src={markdownData.frontmatter.image.publicURL} alt={markdownData.frontmatter.imageAlt} centered />
      </Container>

      <StyledContainer text>
        {renderAst(markdownData.htmlAst)}
      </StyledContainer>
    </>
  )
}
