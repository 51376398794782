import React from "react"
import { Header, Image } from "semantic-ui-react"
import styled from "styled-components"

import sixeyeLogo from "../images/sixeye-logo-white-tight.svg"
import "../styles/scroll-arrow.css"

const StyledFlexDiv = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  text-align: center;
`

const StyledImage = styled(Image)`
  margin-bottom: 2em;
`

const SpacerDiv = styled.div`
  flex: 1 1 auto;
`

const textShadowStyle = {
  textShadow: `2px 2px 5px #333`,
}

export default () => (
  <StyledFlexDiv>
    <SpacerDiv />
    <StyledImage src={sixeyeLogo} alt="SixEye logo" size="big" centered />
    <Header inverted as="h1" style={textShadowStyle}>
      Unified remote management for systems integrators
    </Header>
    <Header as="h2" inverted style={{ marginTop: `0em`, ...textShadowStyle }}>
      your projects / your devices / your cloud
    </Header>
    <SpacerDiv />
    <div
      style={{ position: `relative`, height: `100px`, marginBottom: `3.5em` }}
    >
      <div className="arrow arrow-1"></div>
      <div className="arrow arrow-2"></div>
    </div>
  </StyledFlexDiv>
)
