import React from "react"
import { Link } from "gatsby"
import { Container, Divider, Button, Segment } from "semantic-ui-react"

import { StyledContainer } from "../components/core"
import LayoutParallax from "../components/layout-desktop-parallax"
import ManufacturerRef from "../components/manufacturers/manufacturer-ref"
import SEO from "../components/seo"

import ParallaxImageChildren from "../components/index-parallax-children"
import Intro from "../components/home-intro"
import HighlightFeaturesGrid from "../components/features/highlight-features"
import LargeImageFeaturesGrid from "../components/features/large-image-features"
import HowItWorks from "../components/how-it-works"

import londonEyeImage from "../images/london-eye-1920.jpg"

const IndexPage = () => {
  return (
    <LayoutParallax
      image={londonEyeImage}
      imageChildren={ParallaxImageChildren}
    >
      <SEO />
      <StyledContainer>
        <Intro />
      </StyledContainer>
      <StyledContainer text textAlign="center">
        <Link to="/getting-started/">
          <Button primary size="big">
            Get Started
          </Button>
        </Link>
      </StyledContainer>

      <Divider section />

      <Segment basic padded>
        <StyledContainer>
          <HighlightFeaturesGrid columns={3} />
        </StyledContainer>
      </Segment>

      <Segment basic padded>
        <StyledContainer>
          <LargeImageFeaturesGrid columns={1} />
        </StyledContainer>
        <StyledContainer text textAlign="center">
          <Link to="/features/">
            <Button primary size="big">
              See all features
            </Button>
          </Link>
        </StyledContainer>
      </Segment>

      <Segment secondary basic>
        <HowItWorks />
      </Segment>
      <Segment basic padded>
        <ManufacturerRef title="Manufacturer partners" headerAs="h2" />
        <Container
          text
          textAlign="center"
          style={{
            marginTop: `1.5em`,
          }}
        >
          <Link to="/manufacturers/">Find out more</Link>
          {` `}
          about becoming a SixEye Connected manufacturer.
        </Container>
      </Segment>
    </LayoutParallax>
  )
}

export default IndexPage
