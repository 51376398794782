import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Container, Header } from "semantic-ui-react"
import styled from "styled-components"

import renderAst from "./render-markdown"

const JustifiedDiv = styled.div`
  text-align: justify;
`

export default () => {
  const data = useStaticQuery(graphql`
    query homeIntro {
      markdownRemark(
        fileAbsolutePath: { regex: "/src/content/home/home-intro/" }
      ) {
        htmlAst
        frontmatter {
          title
          subtitle
        }
      }
    }
  `)
  const markdownData = data.markdownRemark
  return (
    <>
      <Header as="h1" textAlign="center">
        {markdownData.frontmatter.title}
        <Header.Subheader>{markdownData.frontmatter.subtitle}</Header.Subheader>
      </Header>
      <Container text>
        <JustifiedDiv>{renderAst(markdownData.htmlAst)}</JustifiedDiv>
      </Container>
    </>
  )
}
