/**
 * Full screen background image with parallax scrolling effect
 */

import React, { useState } from "react"
import PropTypes from "prop-types"
import { Visibility } from "semantic-ui-react"
import styled from "styled-components"

const BackgroundImage = styled.div`
  width: 100vw;
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
`

const ParallaxBackgroundImage = ({ image, children }) => {
  const [pixelsPassed, setPixelsPassed] = useState(0)

  const handleUpdate = (e, { calculations }) =>
    setPixelsPassed(calculations.pixelsPassed)

  return (
    <>
      <Visibility onUpdate={handleUpdate}>
        <BackgroundImage
          style={{
            backgroundImage: `url(${image})`,
            backgroundPositionY: pixelsPassed / 2.0,
          }}
        >
          {children}
        </BackgroundImage>
      </Visibility>
    </>
  )
}

ParallaxBackgroundImage.propTypes = {
  image: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default ParallaxBackgroundImage
