import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import { FeatureItemLargeImage } from "./feature-items"
import withFeatureGrid from "./with-feature-grid"

const FeatureItemImageGrid = withFeatureGrid(FeatureItemLargeImage)

export default props => {
  const data = useStaticQuery(graphql`
    query {
      features: allFeaturesJson(filter: { tags: { regex: "/large image/" } }) {
        edges {
          node {
            ...FeatureLargeImageFragment
          }
        }
      }
    }
  `)
  return <FeatureItemImageGrid {...props} items={data.features.edges} />
}
